// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC6orY-4vZWCrofOyHYdpRZsob7gaeJlIc",
  authDomain: "admin-eestec-ro.firebaseapp.com",
  projectId: "admin-eestec-ro",
  storageBucket: "admin-eestec-ro.appspot.com",
  messagingSenderId: "811892057525",
  appId: "1:811892057525:web:63cb75ab0b2752c5cce5fc"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);