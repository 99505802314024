<template>
  <div v-if="$store.state.userIsLoggedIn">
    <div class="font-montserrat text-2xl flex gap-10 text-white font-bold w-full justify-end pr-20 py-5 bg-dark_red">
      <!-- <router-link to="/" :class="{'underline': currentPath == '/'}">Login</router-link> -->
      <router-link to="/home" :class="{'underline': currentPath == '/home'}">Home</router-link>
      <router-link to="/articles" :class="{'underline': currentPath == '/articles'}">Articles</router-link>
      <router-link to="/events" :class="{'underline': currentPath == '/events'}">Events</router-link>
    </div>
  </div>
  <router-view/>
</template>

<script>
export default {
  data() {
    return {
      currentPath: "",
    }
  },
  created() {
    // console.log(this.$router.currentRoute._value.path);
  },
  methods: {

  },
  watch: {
    $route() {
      this.currentPath = this.$router.currentRoute._value.path;
      // console.log(this.currentPath);
    }
  }
}

</script>

<style lang="scss">
#app {
  max-width: 100vw;
  overflow-x: hidden;
}
</style>
