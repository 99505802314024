<template>
  <div id="login-page">
    <div>
      <img src="https://i.imgur.com/z86qqgA.jpg" alt="background image" loading="lazy"
           class="fixed z-0 inset-0 object-cover w-screen h-screen"/>
      <div class="fixed w-screen h-screen inset-0 bg-darker_red opacity-30 z-10"/>
      <div class="relative z-20 ml-20 w-80 h-screen flex flex-col items-center">
        <div class="bg-pink absolute inset-0 z-0 opacity-90"/>
        <p class="relative z-10 font-montserrat text-center text-3xl font-bold text-dark_red mt-20"> EESTEC Bucharest Admin Console</p>
        <button @click="login()" class="relative z-10 font-roboto text-xl text-white bg-dark_red px-16 py-1 rounded-md my-10">Login</button>
        <p class="relative z-10 font-roboto text-center text-dark_grey text-sm px-5"> Only EESTEC members which have been authorized can login. For access please contact the current VP-IT. </p>
      </div>
    </div>
  </div>
</template>

<script>
import { GoogleAuthProvider, signInWithPopup, getAuth, signOut  } from "firebase/auth";

export default {
  data() {
    return {
      approvedUsers: [
        "stefan.mircea@eestec.ro",
        "andrei.diaconu@eestec.ro",
        "gabriela.nitoi@eestec.ro",
        "andrei.soare@eestec.ro",
        "hanga@mihail.eestec.ro",
        "robert.stanca@eestec.ro"
      ]
    }
  },
  methods: {
    login() {
      const auth = getAuth();
      const provider = new GoogleAuthProvider();
      signInWithPopup(auth, provider)
        .then((result) => {
          if(!this.approvedUsers.includes(result.user.email)) {
            signOut(auth);
            this.$store.state.userIsLoggedIn = false;
            alert("Only EESTEC Bucuresti members can use this application!");
          } else {
            console.log(result);
            this.$router.push('/home');
            this.$store.state.userIsLoggedIn = true;
            this.$store.state.currentUserInfo.photo = result.user.photoURL;
            this.$store.state.currentUserInfo.name = result.user.displayName;
          }

          /* 
          rules_version = '2';
          service cloud.firestore {
            match /databases/{database}/documents {
              match /{document=**} {
                allow read, write: if request.auth.token.email.matches('.*@domain[.]com');
              }
            }
          }

          -> implement this rule in the backend for articles, only for write
          */
        });
    },
    signOut() {
      const auth = getAuth();
      signOut(auth).then(() => {
        console.log('signedOut');
      }).catch((error) => {
        console.log(error);
      });
    }
  }
}
</script>

<style lang="scss" scoped>

</style>