<template>
  <div id="home" class="bg-pink min-h-screen px-10">
     <div v-if="pageIsLoading" class="flex justify-center items-center w-screen h-80" >
      <PulseLoader :loading="pageIsLoading" color="#9E0C14" size="30px"/>
    </div>
    <Banner @loading="(isLoading) => pageIsLoading = isLoading" :pageIsLoading="pageIsLoading"/>
  </div>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import Banner from '../components/home/Banner.vue';

export default {
  components: {
    PulseLoader,
    Banner
  },
  data() {
    return {
      pageIsLoading: true,
      banner: {},
    }
  },
};
</script>
