<template>
  <div id="banner">
    <div v-if="!pageIsLoading" class="pt-4 mb-16">
      <h1 class="font-montserrat text-3xl text-dark_red my-10 text-center font-bold block underline"> Banner </h1>
      <div class="flex my-2">
        <p  class="font-montserrat text-lg text-black mr-2"> Banner Message: </p>
        <input v-model="banner.message" class="border font-roboto w-4/5"/>
      </div>
      <div class="flex">
        <p  class="font-montserrat text-lg text-black mr-2"> Banner status: {{bannerStatusMessage}} </p>
        <input type="checkbox" v-model="banner.isActive"/>
      </div>
      <button @click="saveBanner()" class="font-roboto text-lg text-white bg-dark_red px-4 py-1 rounded-md mt-2"> Save </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pageIsLoading: Boolean,
  },
  data() {
    return {
      banner: {},
    }
  },
  computed: {
    bannerStatusMessage() {
      return this.banner.isActive ? "Active" : "Inactive";
    }
  },
  methods: {
    saveBanner() {
      this.$emit('loading', true);
      fetch(`https://us-central1-eestec-backend.cloudfunctions.net/app/api/admin/banner`, {
        method: 'PUT',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.banner)
      })
      .then( response => response.json().then( parsedResponse => {
        // console.log(parsedResponse);
        })
      ).catch( error => {
        console.log(error);
        alert('The banner wasn\'t saved.')
      }).finally( _ => {
         this.$emit('loading', false);
      });
    },
    getBanner() {
      fetch(`https://us-central1-eestec-backend.cloudfunctions.net/app/api/admin/banner`, {method: 'GET'})
      .then( response => response.json().then( parsedResponse => {
        // console.log(parsedResponse);
        this.banner = parsedResponse;
        })
      ).catch( error => {
        console.log(error);
      }).finally( _ => {
        this.$emit('loading', false);
      });
    }
  },
  created() {
    this.getBanner();
  }
}
</script>