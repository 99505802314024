import { createRouter, createWebHashHistory } from 'vue-router';
import HomeView from '../views/Home.vue';
import LoginView from '../views/Login.vue';
import ArticlesView from '../views/Articles.vue';
import EventsView from '../views/Events.vue';
import { getAuth } from 'firebase/auth';

const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginView
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView,
    meta: {
      authRequired: true,
    }
  },
  {
    path: '/articles',
    name: 'articles',
    component: ArticlesView,
    meta: {
      authRequired: true,
    },
    children: [
      {
        path: ":articleName",
        name: "ArticleDetail",
        component: () => import("../components/articles/ArticleDetail.vue"),
      },
    ],
  },
  {
    path: '/events',
    name: 'events',
    component: EventsView,
    meta: {
      authRequired: true,
    },
    children: [
      {
        path: ":eventName",
        name: "EventDetail",
        component: () => import("../components/events/EventDetail.vue"),
      },
    ],
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

// router.beforeEach((to, from, next) => {
//   if(to.matched.some(record => record.meta.authRequired)) {
//       const auth = getAuth();       
//       if(auth.currentUser) {
//         next();
//       } else {
//         alert('You must be logged in to view this page!');
//         next({
//           path: '/'
//         });
//       }
//   } else {
//     next();
//   }
// });

export default router;
