<template>
  <div id="articles-page" class="bg-pink min-h-screen">
    <div v-if="pageIsLoading" class="flex justify-center items-center w-screen h-80" >
      <PulseLoader :loading="pageIsLoading" color="#9E0C14" size="30px"/>
    </div>
    <div v-if="!$route.params.articleName && !pageIsLoading">
      <p class=" font-montserrat text-3xl text-dark_red py-10 font-bold text-center"> All Articles </p>
      <button @click="goToArticlePage('newArticle')" class="font-roboto text-xl text-white bg-dark_red px-2 py-1 rounded-md mx-auto block"> Create Article </button>
      <div class="flex flex-wrap px-20 justify-start items-center">
        <div 
          v-for="(article, index) in articles" 
          :key="index"
          class="bg-white mt-10 mx-10 w-100 px-10 py-5 rounded-lg shadow"
        >
          <p class=" font-montserrat text-xl text-dark_red mb-5 font-semibold"> {{article.articleName}}</p>
          <div>
            <button @click="goToArticlePage(article.articleName)" class="font-roboto text-lg text-white bg-dark_red px-2 py-1 rounded-md mr-10"> Edit </button>
            <button @click="articleToDelete = article.articleName; showModal = true" class="font-roboto text-lg text-white bg-dark_red px-2 py-1 rounded-md"> Delete </button>
          </div>
        </div>
      </div>
    </div>
    <router-view/>
    <VueFinalModal v-model="showModal" classes="modal-container" content-class="modal-content">
      <div class="modal-title"> Are you sure you want to delete this article? </div>
      <div class="flex justify-around mt-5">
        <button @click="deleteArticle()" class="font-roboto text-lg text-white bg-dark_red px-5 py-1 rounded-md"> Yes </button>
        <button @click="showModal = false" class="font-roboto text-lg text-white bg-dark_red px-5 py-1 rounded-md"> No </button>
      </div>
    </VueFinalModal>
  </div>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import { VueFinalModal } from 'vue-final-modal';

export default {
  components: {
    PulseLoader,
    VueFinalModal
  },
  data() {
    return {
      articles: [],
      pageIsLoading: true,
      showModal: false,
      articleToDelete: "",
    }
  },
  methods: {
    goToArticlePage(articleName) {
      this.$router.push({ name: 'ArticleDetail', params: { articleName } });
    },
    deleteArticle() {
      this.showModal = false;
      fetch(`https://us-central1-eestec-backend.cloudfunctions.net/app/api/articles/${this.articleToDelete}`, {method: 'DELETE'})
      .then( response => response.json().then( parsedResponse => {
          // console.log(parsedResponse);
          this.pageIsLoading = true;
          this.getAllArticles();
        })).catch(error => {
          this.pageIsLoading = false;
          alert("The article wasn't deleted");
        }); 
    },
    getAllArticles() {
      fetch('https://us-central1-eestec-backend.cloudfunctions.net/app/api/articles/', {method: 'GET'})
      .then( response => response.json().then( parsedResponse => {
        this.articles = parsedResponse;
        // console.log(parsedResponse);
      })).catch( error => {
        console.log(error);
        alert("The articles couldn't be retrieved")
      }).finally( _ => {
        this.pageIsLoading = false;
      });
    },
  },
  created() {
    this.getAllArticles();
  },
}
</script>

<style lang="scss" scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
}
.modal-title {
  font-size: 1.5rem;
  font-weight: 700;
}
</style>